<template>
  <div class="container">
    <div>
      {{ type }}
    </div>
    <div>
      <v-icon>{{ icons["mobile"] }}</v-icon>
      <span>
        {{ formatCwvMetric(values[15], type) }}
      </span>
    </div>

    <div>
      <v-icon>{{ icons["tablet"] }}</v-icon>
      {{ formatCwvMetric(values[17], type) }}
    </div>
    <div>
      <v-icon>{{ icons["desktop"] }}</v-icon>
      {{ formatCwvMetric(values[16], type) }}
    </div>
    <div>
      <v-icon>{{ icons["global"] }}</v-icon>
      {{ formatCwvMetric(values["global"], type) }}
    </div>
  </div>
</template>

<script>
import { mdiTablet, mdiCellphone, mdiMonitor, mdiWeb } from "@mdi/js";
import { formatCwvMetric } from "@/components/web-perf/cwv/cwv-sites-performance/utils/utils";
export default {
  name: "StatusTooltip",
  props: {
    type: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        tablet: mdiTablet,
        mobile: mdiCellphone,
        desktop: mdiMonitor,
        global: mdiWeb,
      },
    };
  },
  methods: {
    formatCwvMetric,
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .icon span {
    font-size: 1.5rem;
  }
}
</style>
