<template>
  <HoverTooltip>
    <div class="status-container">
      <div
        class="status-item first"
        :style="{
          backgroundColor: colors[getColorByRange(type, values[15])].light,
        }"
      >
        <v-icon
          :color="colors[getColorByRange(type, values[15])].dark"
          :size="iconSize"
          >{{ icons.mdiCircle }}</v-icon
        >
        {{ formatCwvMetric(values[15], type) }}
      </div>
      <!--      <div-->
      <!--        class="status-item"-->
      <!--        :style="{-->
      <!--          backgroundColor: colors[getColorByRange(type, values[17])].light,-->
      <!--        }"-->
      <!--      >-->
      <!--        <v-icon-->
      <!--          :color="colors[getColorByRange(type, values[17])].dark"-->
      <!--          :size="iconSize"-->
      <!--          >{{ icons.mdiCircle }}</v-icon-->
      <!--        >-->
      <!--        {{ formatCwvMetric(values[17], type) }}-->
      <!--      </div>-->
      <div
        class="status-item last"
        :style="{
          backgroundColor: colors[getColorByRange(type, values[16])].light,
        }"
      >
        <v-icon
          :color="colors[getColorByRange(type, values[16])].dark"
          :size="iconSize"
          >{{ icons.mdiCircle }}</v-icon
        >
        {{ formatCwvMetric(values[16], type) }}
      </div>
    </div>
    <template v-slot:tooltip>
      <StatusTooltip :type="type" :values="values" />
    </template>
  </HoverTooltip>
</template>

<script>
import HoverTooltip from "@/components/common/HoverTooltip.vue";
import StatusTooltip from "@/components/web-perf/cwv/cwv-sites-performance/cards/StatusTooltip.vue";
import {
  mdiCellphone,
  mdiTablet,
  mdiMonitor,
  mdiCircle,
  mdiCircleOutline,
} from "@mdi/js";
import { ranges } from "@/components/web-perf/cwv/cwv-sites-performance/utils/utils";
import { formatCwvMetric } from "@/components/web-perf/cwv/cwv-sites-performance/utils/utils";

export default {
  name: "StatusCard",
  components: { HoverTooltip, StatusTooltip },
  data() {
    return {
      hover: false,
      isVisible: false,
      cursorX: 0,
      cursorY: 0,
      inactivityTimer: null,
      iconSize: 18,
      ranges,
      colors: {
        red: {
          light: "#ffdfdd",
          dark: "#f44335",
        },
        orange: {
          light: "#feddaa",
          dark: "#ff9800",
        },
        green: {
          light: "#e3ffc3",
          dark: "#8bc34a",
        },
      },
      icons: {
        mdiCellphone,
        mdiTablet,
        mdiMonitor,
        mdiCircle,
        mdiCircleOutline,
      },
    };
  },
  props: {
    type: String,
    values: Object,
  },
  methods: {
    formatCwvMetric,
    getColorByRange(metric, value) {
      const range = this.ranges[metric];
      if (value <= range[0]) {
        return "green";
      } else if (value <= range[1]) {
        return "orange";
      } else {
        return "red";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.status-container {
  display: inline-flex;
  overflow: hidden;
  border-radius: 16px;
  font-size: 12px;
  color: black;
}

.status-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
}

/* Fix the border radius issue */
//.first {
//border-radius: 20px 0 0 20px;
//}

//.last {
//border-radius: 0 20px 20px 0;
//}
</style>
