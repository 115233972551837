<template>
  <v-card class="mb-6 pa-2">
    <v-row class="ma-3">
      <v-col cols="4">
        <site-group-filter store="webPerf"></site-group-filter>
      </v-col>
      <v-col cols="4">
        <site-filter store="webPerf"></site-filter>
      </v-col>
      <v-col cols="2">
        <apply-filters
          :disabled="disabled"
          outlined
          color="secondary"
          title="Filtrer"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter.vue";
import DeviceFilter from "@/components/common/filters/DeviceFilter.vue";
import MetricFilter from "@/components/web-perf/cwv/filters/filters/MetricFilter.vue";
import PageTypeFilter from "@/components/web-perf/cwv/filters/filters/PageTypeFilter.vue";
import EvolutionFilter from "@/components/web-perf/cwv/filters/filters/EvolutionFilter.vue";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter.vue";
import CategoryFilter from "@/components/common/filters/CategoryFilter.vue";

export default {
  name: "Filters",
  props: {},
  components: {
    ApplyFilters,
    SiteFilter,
    SiteGroupFilter,
  },
  computed: {
    disabled: {
      get() {
        return this.$store.getters["common/getApplyFilters"];
      },
      set(value) {
        this.$store.commit("common/updateApplyFilters", value);
      },
    },
  },
  watch: {},
};
</script>

<style scoped></style>
