<template>
  <v-card class="my-8">
    <v-card-title>Problèmes critiques</v-card-title>
    <v-card-text>
      <div class="my-6">
        <template v-if="!problems.length">
          <span class="font-weight-light text--primary">
            Aucun problème critique
          </span>
        </template>
        <CriticalProblemCard
          v-for="problem in problems"
          :key="problem.siteName"
          :siteName="problem.siteName"
          :lcp="problem.lcp"
          :cls="problem.cls"
          :inp="problem.inp"
          :ttfb="problem.ttfb"
          :fcp="problem.fcp"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import { ranges } from "@/components/web-perf/cwv/cwv-sites-performance/utils/utils";
import CriticalProblemCard from "@/components/web-perf/cwv/cwv-sites-performance/cards/CriticalProblemCard.vue";
export default {
  name: "CriticalProblemsGroupCards",
  components: { CriticalProblemCard },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  data() {
    return {
      ranges,
      problems: [],
      loading: false,
      total: 0,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = {};
      if (this.sites && this.sites.length > 0) {
        params.sites = this.sites.join(",");
      }
      const { data } = await this.axiosGet("/web-perf/cwv-daily-sites", params);
      this.problems = this.checkMetrics(data.items);
    },
    checkMetrics(data) {
      return data
        .map((site) => {
          const issues = {};

          if (site.lcp >= ranges.LCP[1]) issues.lcp = { rum: true };
          if (site.inp >= ranges.INP[1]) issues.inp = { rum: true };
          if (site.cls >= ranges.CLS[1]) issues.cls = { rum: true };
          if (site.fcp >= ranges.FCP[1]) issues.fcp = { rum: true };
          if (site.ttfb >= ranges.TTFB[1]) issues.ttfb = { rum: true };

          if (Object.keys(issues).length > 0) {
            return {
              siteName: site.site_name,
              ...issues,
            };
          }
          return null;
        })
        .filter((item) => item !== null);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["webPerf/getSites"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>

<style scoped></style>
