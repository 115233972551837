<template>
  <v-card :outlined="outlined" :class="classNames">
    <v-card-title>
      {{ title }}
      <dag-info :dags="dags" />
    </v-card-title>
    <v-card-subtitle v-if="subTitle">{{ subTitle }}</v-card-subtitle>
    <loader v-if="loading" />
    <v-card-text
      v-else-if="series.length === 0 || series[0].data.length === 0"
      >{{ noDataText }}</v-card-text
    >
    <div v-else>
      <vue-apex-charts
        type="line"
        :height="height"
        :options="state.chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { onMounted, reactive, set, watch } from "@vue/composition-api";

import DagInfo from "@/components/common/menus/DagInfo";
import Loader from "@/components/common/Loader";

import { noDataText } from "@/utils/constants";

export default {
  name: "LineChart",
  components: {
    DagInfo,
    Loader,
    VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    classNames: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Boolean,
      default: true,
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => ["#F44336"],
    },
    stroke: {
      type: Object,
      default: () => ({
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [0, 2],
      }),
    },
    height: {
      type: Number,
      default: 350,
    },
    labelsEnabled: {
      type: Boolean,
      default: false,
    },
    yAxis: {
      type: Array,
      default: () => [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
    },
    xAxis: {
      type: Object,
      default: () => ({
        categories: [],
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      }),
    },
    dags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    });

    // =>  METHODS
    const updateOptions = () => {
      set(state, "chartOptions", {
        ...state.chartOptions,
        labels: props.labels,
        chart: {
          ...state.chartOptions.chart,
          id: props.title,
          height: props.height,
        },
        legend: {
          show: props.legend,
        },
        colors: props.colors,
        dataLabels: {
          enabled: props.labelsEnabled,
        },
        stroke: props.stroke,
        yaxis: props.yAxis,
        xaxis: props.xAxis,
      });
    };

    // =>  COMPUTED

    // =>  WATCH
    watch(
      () => props.height,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.colors,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.labelsEnabled,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.stroke,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.yAxis,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.xAxis,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.labels,
      () => {
        updateOptions();
      }
    );

    // =>  HOOKS
    onMounted(() => {
      updateOptions();
    });

    // =>  DATA
    return {
      state,
      noDataText,
    };
  },
};
</script>
