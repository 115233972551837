var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Liste des sites")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"sort-by":'rum_score',"sort-desc":false,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.lcp",fn:function(ref){
      var item = ref.item;
return [_c('status-card',{attrs:{"type":'LCP',"values":item.lcp}})]}},{key:"item.cls",fn:function(ref){
      var item = ref.item;
return [_c('status-card',{attrs:{"type":'CLS',"values":item.cls}})]}},{key:"item.inp",fn:function(ref){
      var item = ref.item;
return [_c('status-card',{attrs:{"type":'INP',"values":item.inp}})]}},{key:"item.ttfb",fn:function(ref){
      var item = ref.item;
return [_c('status-card',{attrs:{"type":'TTFB',"values":item.ttfb}})]}},{key:"item.fcp",fn:function(ref){
      var item = ref.item;
return [_c('status-card',{attrs:{"type":'FCP',"values":item.fcp}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }