<template>
  <span class="container mr-3">
    <template v-if="lcp">
      <span v-if="lcp.rum" class="rum"> LCP </span>
      <span v-if="lcp.crux" class="crux"> LCP </span>
    </template>
    <template v-if="cls">
      <span v-if="cls.rum" class="rum"> CLS </span>
      <span v-if="cls.crux" class="crux"> CLS </span>
    </template>
    <template v-if="inp">
      <span v-if="inp.rum" class="rum"> INP </span>
      <span v-if="inp.crux" class="crux"> INP </span>
    </template>
    <template v-if="ttfb">
      <span v-if="ttfb.rum" class="rum"> TTFB </span>
      <span v-if="ttfb.crux" class="crux"> TTFB </span>
    </template>
    <template v-if="fcp">
      <span v-if="fcp.rum" class="rum"> FCP </span>
      <span v-if="fcp.crux" class="crux"> FCP </span>
    </template>
    <span class="font-weight-semibold text--primary site-name">
      {{ siteName }}
    </span>
  </span>
</template>
<script>
export default {
  name: "CriticalProblemCard",
  props: {
    siteName: {
      type: String,
      required: true,
    },
    lcp: {
      type: Object,
      required: false,
    },
    cls: {
      type: Object,
      required: false,
    },
    inp: {
      type: Object,
      required: false,
    },
    ttfb: {
      type: Object,
      required: false,
    },
    fcp: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      red: {
        light: "#ffdfdd",
        dark: "#f44335",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$red-light: #ffdfdd;
$red-dark: #f44335;

.container {
  border-radius: 18px;
  padding: 8px 12px;
  font-size: 10px;
  background-color: $red-light;
  .rum {
    background-color: $red-dark;
    color: white;
    border: 2px solid white;
    margin: 0 4px;
    padding: 0 4px;
    border-radius: 4px;
    font-weight: bold;
  }
  .crux {
    background-color: white;
    color: $red-dark;
    border: 2px solid $red-dark;
    margin: 0 4px;
    padding: 0 4px;
    border-radius: 4px;
    font-weight: bold;
  }
  .site-name {
    font-size: 12px;
  }
}
</style>
