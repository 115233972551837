<template>
  <v-card class="my-8">
    <v-card-title>Progression des scores</v-card-title>
    <v-card-text>
      <line-chart
        :series="series"
        :labels="labels"
        :x-axis="xaxis"
        :y-axis="yaxis"
        :colors="colors"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { chartColors } from "@/utils/constants";
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import LineChart from "@/components/common/charts/LineChart.vue";

export default {
  name: "ScoreProgressionChart",
  components: { LineChart },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  data() {
    return {
      colors: chartColors,
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            return new Date(val).toLocaleDateString();
          },
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        forceNiceScale: false,
      },
      series: [],
      labels: [],
      total: 0,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = {};
      if (this.sites && this.sites.length > 0) {
        params.sites = this.sites.join(",");
      }
      const { data } = await this.axiosGet(
        "/web-perf/cwv-scores-last-30-days",
        params
      );
      this.series = data.items.reduce((acc, { site_name, date, rum_score }) => {
        let siteData = acc.find((item) => item.name === site_name);
        if (!siteData) {
          siteData = { name: site_name, data: [] };
          acc.push(siteData);
        }
        siteData.data.push({ x: new Date(date).getTime(), y: rum_score });
        return acc;
      }, []);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["webPerf/getSites"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>

<style scoped></style>
