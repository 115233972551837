<template>
  <v-card>
    <v-card-title>Liste des sites</v-card-title>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'rum_score'"
        :sort-desc="false"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.lcp="{ item }">
          <status-card :type="'LCP'" :values="item.lcp" />
        </template>
        <template v-slot:item.cls="{ item }">
          <status-card :type="'CLS'" :values="item.cls" />
        </template>
        <template v-slot:item.inp="{ item }">
          <status-card :type="'INP'" :values="item.inp" />
        </template>
        <template v-slot:item.ttfb="{ item }">
          <status-card :type="'TTFB'" :values="item.ttfb" />
        </template>
        <template v-slot:item.fcp="{ item }">
          <status-card :type="'FCP'" :values="item.fcp" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style scoped></style>
<script>
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import { formatNumber } from "@/utils/formatting";
import StatusCard from "@/components/web-perf/cwv/cwv-sites-performance/cards/StatusCard.vue";
export default {
  name: "CwvSitesPerformanceTable",
  components: { StatusCard },
  props: {
    devFilterVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      deviceMapping: {
        15: "Mobile",
        16: "Desktop",
        17: "Tablette",
      },
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      ranges: {
        LCP: [2500, 4000],
        INP: [200, 500],
        CLS: [0.1, 0.25],
        FCP: [1800, 3000],
        TTFB: [800, 1800],
      },
      headers: [
        {
          text: "Site",
          value: "site_name",
        },
        {
          text: "LCP",
          value: "lcp",
        },
        {
          text: "CLS",
          value: "cls",
        },
        {
          text: "INP",
          value: "inp",
        },
        {
          text: "TTFB",
          value: "ttfb",
        },
        {
          text: "FCP",
          value: "fcp",
        },
        {
          text: "Score RUM",
          value: "rum_score",
        },
        {
          text: "Old Score RUM",
          value: "old_rum_score",
        },
      ],
    };
  },
  methods: {
    formatNumber,
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = {};
      if (this.sites && this.sites.length > 0) {
        params.sites = this.sites.join(",");
      }
      this.loading = true;

      const { data } = await this.axiosGet(
        "/web-perf/cwv-sites-performance",
        params
      );
      const results = [];
      for (let [key, item] of Object.entries(data.items)) {
        results.push({
          ...item,
        });
      }
      this.items = results;

      this.loading = false;
      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["webPerf/getSites"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
