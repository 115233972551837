<template>
  <div
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousemove="onMouseMove"
    style="display: inline-block"
  >
    <slot></slot>
    <transition name="fade">
      <div
        v-if="isVisible"
        :style="{
          position: 'fixed',
          top: cursorY + offsetY + 'px',
          left: cursorX + offsetX + 'px',
          ...tooltipStyle,
          pointerEvents: 'none',
          zIndex: 1000,
        }"
      >
        <slot name="tooltip">I'm a fancy tooltip</slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "HoverTooltip",
  props: {
    timeout: {
      type: Number,
      default: 3000,
    },
    offset: {
      type: Number,
      default: 15,
    },
    tooltipStyle: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        padding: "8px 12px",
        fontSize: "14px",
        color: "#333",
      }),
    },
  },
  data() {
    return {
      isVisible: false,
      cursorX: 0,
      cursorY: 0,
      offsetX: 0,
      offsetY: 0,
      inactivityTimer: null,
    };
  },
  methods: {
    onMouseEnter(event) {
      this.showTooltip();
      this.updatePosition(event);
    },
    onMouseLeave() {
      this.hideTooltip();
    },
    onMouseMove(event) {
      this.updatePosition(event);
      this.resetInactivityTimer();
    },
    updatePosition(event) {
      this.cursorX = event.clientX;
      this.cursorY = event.clientY;

      this.offsetX = this.offset;
      this.offsetY = this.offset;
    },
    showTooltip() {
      this.isVisible = true;
      this.resetInactivityTimer();
    },
    hideTooltip() {
      clearTimeout(this.inactivityTimer);
      this.isVisible = false;
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(() => {
        this.isVisible = false;
      }, this.timeout);
    },
  },
  beforeDestroy() {
    clearTimeout(this.inactivityTimer);
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.2s ease-in;
}
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
