<template>
  <div>
    <Filters />
    <CriticalProblemsGroupCards />
    <CwvSitesPerformanceTable />
    <ScoreProgressionChart />
  </div>
</template>

<script>
import Filters from "@/components/web-perf/cwv/cwv-sites-performance/filters/Filters.vue";
import CwvSitesPerformanceTable from "@/components/web-perf/cwv/cwv-sites-performance/tables/CwvSitesPerformanceTable.vue";
import CriticalProblemsGroupCards from "@/components/web-perf/cwv/cwv-sites-performance/cards/CriticalProblemsGroupCards.vue";
import ScoreProgressionChart from "@/components/web-perf/cwv/cwv-sites-performance/charts/ScoreProgressionChart.vue";
export default {
  name: "CwvSitesPerformance",
  components: {
    ScoreProgressionChart,
    CriticalProblemsGroupCards,
    CwvSitesPerformanceTable,
    Filters,
  },
  created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "largeWidth");
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  data() {
    return {};
  },
};
</script>
